import React from 'react'
import { graphql } from "gatsby"
import styled from 'styled-components';
import { Date } from 'prismic-reactjs'
import { linkResolver } from 'utils/linkResolver.js'
import { H4, P, Link } from 'style';
import {
  mobileHdWidth,
  paddingWrapperMd,
  paddingWrapperLg,
  paddingXl,
  tabletWidth,
} from 'style/constants';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${paddingWrapperMd};
  margin-bottom: -${paddingWrapperMd};
  justify-content: space-between;

  @media (min-width: ${mobileHdWidth}) {
    padding-bottom: ${paddingXl};
    padding-top: ${paddingWrapperLg};
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: start;
  }
`;

const PostWrapper = styled.header`
  display: block;
  width: 100%;
`;

const Wrapper = styled.div`
  display: inline-block;
  flex-direction: column;
  width: 100%;
  padding-bottom: ${paddingWrapperMd};

  @media (min-width: ${tabletWidth}) {
    width: calc((100% / 2) - (2 * (${paddingWrapperMd} / 2)));
    margin-left: ${paddingWrapperMd};

    &:nth-child(2n + 1) {
      margin-left: 0;
    }
  }

  @media (min-width: ${mobileHdWidth}) and (max-width: ${tabletWidth}) {
    width: calc(50% - (${paddingWrapperMd} / 2));
    margin-left: ${paddingWrapperMd};

    &:nth-child(2n + 1) {
      margin-left: 0;
    }
  }

  @media (max-width: ${mobileHdWidth}) {
    display: ${props => (props.hideMobile ? 'none' : 'flex')};
  }
`;

const firstParagraph = (post => {
  let firstTextSlice = post.node.data.body.find(slice => slice.slice_type === 'text');
  if (firstTextSlice != null) {
    const textLimit = 300
    let text = firstTextSlice.primary.text.text
    let limitedText = text.substring(0, textLimit)
    if (text.length > textLimit) {
      return (
        <P>{limitedText.substring(0, limitedText.lastIndexOf(' ')) + '...'}</P>
      );
    } else {
      return <p>{text}</p>;
    }
  } else {
    return null;
  }
})

const PostSummary = ({ post }) => {
  let postDate = Date(post.node.data.date);
  postDate = postDate ?
    new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: '2-digit',
      year: 'numeric'
    }).format(postDate) :
    '';

  const { title } = post.node.data || {};
  const titleText = title ? title.text : 'Untitled';

  return (
    <PostWrapper>
      <a href={post.node.data.press_link.url} style={{ textDecoration: "none" }}>
        <H4>{titleText}</H4>
      </a>
      <P>{post.node.data.publication.text} on <time>{postDate}</time></P>
      <P>{firstParagraph(post)}</P>
    </PostWrapper>
  );
}


export default ({ posts, data }) => {

  if (!posts) return null;
  return (
    <Container>
      {posts.map((post) => (
        <Wrapper key={post.node.uid}>
          <PostSummary post={post} key={post.id} />
        </Wrapper>
      ))}
    </Container>
  )
}
