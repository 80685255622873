import React from "react"
import { graphql } from "gatsby"
import styled from 'styled-components';
import { Head } from 'components';
import PressReleases from '../components/sections/PressReleases'
import { H2, P } from 'style';
import {
	backgroundGray,
	desktopWidth,
	fontSizeMd,
	fontSizeLg,
	labelGray,
	mobileHdWidth,
	paddingWrapperSm,
	paddingWrapperMd,
	paddingWrapperXl,
	paddingWrapperXxl,
	paddingLg,
	tabletWidth,
	white,
} from 'style/constants';

const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: ${paddingWrapperXxl} ${paddingWrapperMd};
  background-color: ${props => (props.dark ? backgroundGray : white)};

  @media (max-width: ${tabletWidth}) {
    padding-top: ${paddingWrapperXl};
    padding-bottom: ${paddingWrapperXl};
  }

  @media (max-width: ${mobileHdWidth}) {
    padding-left: ${paddingWrapperSm};
    padding-right: ${paddingWrapperSm};
  }
`;

const Wrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: ${desktopWidth};
`;

const Header = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  h2 {
    text-align: center;
  }

  p {
    max-width: 650px;
    text-align: center;
    color: ${labelGray};
    font-size: ${fontSizeLg};
    padding-top: ${paddingLg};

    @media (max-width: ${tabletWidth}) {
      font-size: ${fontSizeMd};
    }
  }
`;

/* #region  Blog Query */
export const query = graphql`
{
	allPrismicPost(filter: {data: {press_release: {eq: true}}}, sort: {fields: data___date, order: DESC}) {
	  edges {
		node {
		  id
		  uid
		  type
		  data {
			body {
				... on PrismicPostBodyText {
				  slice_type
				  primary {
					text {
					  html
					  text
					}
				  }
				}
			  }
			date
			press_release
			publication {
				text
			  }
			press_link{
				url
			}
			title {
			  html
			  text
			}
		  }
		}
	  }
	}
	allPrismicPressHome {
	  edges {
		node {
		  id
		  type
		  data {
			description {
			  html
			  text
			}
			main_title {
			  html
			  text
			}
		  }
		}
	  }
	}
  }
  
`
/* #endregion */

export default ({ data }) => {
	const doc = data.allPrismicPressHome.edges.slice(0, 1).pop()
	const posts = data.allPrismicPost.edges
	if (!doc) return null
	return (
		<React.Fragment>
			<Head title="Press" description={"Wunderite in the News: " + posts[0].node.data.title.text} />
			<Container>
				<Wrapper>
					<Header>
						<H2>{doc.node.data.main_title.text}</H2>
						{doc.node.data.description.text && <P>{doc.node.data.description.text}</P>}
					</Header>
					<PressReleases posts={posts} />
				</Wrapper>
			</Container>
		</React.Fragment>
	)
}
